import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.48466257668711%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsSAAALEgHS3X78AAABrUlEQVQoz3WSe6/BQBDF9/t/HiLxKBcRzwSl1LPqERStVuPGpb0/u5FIxPyxmZ2dxzlzVkRRFMdxGIbb7fZwOFyv11ja8XjcbDZEPM/DJ87pui7B/X5P/Ha7CVXs+36tVsvn87ypYsuyNE1LJpOclUrlfD7ncjmu2WwWJ5PJOI4jSJpOp4vFYrlcUmnbNr5yJpPJbDZbr9eXy4UrA3H8l51OJ0GNaZrD4XA+n9NlPB5z7nY7WAwGA4qpAQhBrlAA7Z+0+/0u4i9GHsRoD2DmjEYj8BeLRXoR8aQ9J9vSgAdgTrWker2eSCTgRpAW7Xa7XC43Gg2wkAA0ughwGobR7XaBDXh4gofJtOv3+zAKgoDrarWinfIfj0ck7StsxYoMxRDO9KUMzX5fJqIPI4NixiIMJIGKvMDmWigUQIvgIH9K9TlTKa/rejqdTqVSnU6HDbFqFAY5zPkOSI2c4h3Gu/V6PbZVKpUcabSg5kcacNjOU2cloNoN0yCGjzZstVqtUsMEtOW12WxCgUir1eLkdwmGkAorfk8gDZDApiPYyKOYCKP4pHxh9QWRBo3/AUe8upjd0a6sAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "headtable",
          "title": "headtable",
          "src": "/static/436fe701b9a8cc22faa9f73103fb0700/a6d36/headtable.png",
          "srcSet": ["/static/436fe701b9a8cc22faa9f73103fb0700/222b7/headtable.png 163w", "/static/436fe701b9a8cc22faa9f73103fb0700/ff46a/headtable.png 325w", "/static/436fe701b9a8cc22faa9f73103fb0700/a6d36/headtable.png 650w", "/static/436fe701b9a8cc22faa9f73103fb0700/e548f/headtable.png 975w", "/static/436fe701b9a8cc22faa9f73103fb0700/99f37/headtable.png 1100w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`Le yin et le yang de la visualisation`}</h3>
    <p>{`Il est souvent dit que les tables et les diagrammes sont les deux piliers de la visualisation de données:`}</p>
    <ul>
      <li parentName="ul">{`le `}<strong parentName="li">{`diagramme`}</strong>{` = la vue d'avion: la population, la tendance, la valeur relative, ...`}</li>
      <li parentName="ul">{`la `}<strong parentName="li">{`table`}</strong>{` = la suite des valeurs exactes: la donnée individuelle, les décimales, le sous-total, ...`}</li>
    </ul>
    <br />
    <p>{`Le `}<strong parentName="p">{`diagramme`}</strong>{` en premier plan attire le chaland, lui communique les messages généraux et la `}<strong parentName="p">{`table`}</strong>{` en arrière plan est toujours prête à répondre aux questions pointues des curieux. `}</p>
    <br />
    <p>{`Sur un écran, il existe un troisième comparse très efficace: `}<strong parentName="p">{`la bulle`}</strong>{` qui apparait quand le curseur survole un point du graphe et qui fournit tous les détails.`}</p>
    <br />
    <h3>{`De belles tables: quelques bons principes`}</h3>
    <p>{`Beaucoup d'énergie et de créativité sont consacrées aux diagrammes et peu en comparaison aux tables. Il n'est pas facile de trouver des contributions marquantes sur le format des tables. Une d'entre elles qui mérite d'être citée est `}<a parentName="p" {...{
        "href": "http://www.amazon.com/Show-Me-Numbers-Designing-Enlighten/dp/0970601972"
      }}>{`le livre de Stephen Few`}</a>{`.`}</p>
    <br />
    <p>{`Quelques recommandations de bon sens:`}</p>
    <ul>
      <li parentName="ul">{`cadrage et alignement: nombre à droite, texte à gauche`}</li>
      <li parentName="ul">{`pas de répétition de symbole dans chaque cellule (€, %)`}</li>
      <li parentName="ul">{`il est plus facile de lire les mots horizontaux qu'inclinés ou verticaux`}</li>
      <li parentName="ul">{`...`}</li>
    </ul>
    <br />
    <p>{`Et aussi des conseils plus avancés:`}</p>
    <ul>
      <li parentName="ul">{`les espacements inter-ligne et inter-colonne sont les premiers responsables du confort de lecture`}</li>
      <li parentName="ul">{`les bordures doivent ajouter de l'information, sinon elles dégradent la lisibilité`}</li>
    </ul>
    <br />
    <br />
    <h3>{`La table structurée`}</h3>
    <p>{`La table structurée a pour objectif de présenter ... des structures composées de champs textuels et de valeurs numériques. Les exemples sont nombreux:`}</p>
    <ul>
      <li parentName="ul">{`Carte restaurant:    Entrée/Plat/Dessert x Ingrédients x recette x Prix de vente`}</li>
      <li parentName="ul">{`Backlog Scrum:       Story/Tâche/ (A faire, A finir, Fini) x Évaluation `}</li>
      <li parentName="ul">{`Portefeuille projets:    Client/Projet/Tâche x (Début, Fin) x Effort x Avancement`}</li>
      <li parentName="ul">{`Matrice de traçabilité: Syst/ss-Syst x (criticité)`}</li>
    </ul>
    <br />
    <p>{`Ces tables sont des listes de champs dans une base de données. L'idée est d'interpréter ces champs comme une hiérarchie de gauche à droite. Les champs qui se répètent d'une ligne à la suivante sont visuellement `}<em parentName="p">{`mis en facteur`}</em>{`. Les tableaux croisées dynamique Excel ou `}<a parentName="p" {...{
        "href": "http://tableausoftware.com"
      }}>{`Tableau`}</a>{` utilisent cette convention visuelle très naturelle.`}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "412px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "73.00613496932516%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABm0lEQVQoz32SaY6CQBCF+15exRt6DCT+URLEH2BccIsyiguKCyjMp2UqhEmmEjp0d716r161CcNwOp0eDocgCMbjcZIkg8FgNpvleV6WZVEUrP1+33XdKIq4chyHn3a7zaFZLpfb7TZNU0rs9/v7/f7ziefzqeBOp9Pr9agOBvBoNOp2u5yYxWIBCamwSSqx2+0ej4eCyabo7XYDT2aWZRwi9g1m83q9YGaV7DiOJeOfoJZZrVb5J9CvVDDTiG4l0AIBPdIzCRhk5vM5SHg2m00VfDqdBMMJtyDR6fs+jh6Px/P5DNBMJhPUco1JCub6crm0Wi0sZWtZFt0hDbepK8yoMHx4ADl9KhhayjUajWazKVtyuJIRSFyvV4N0ATOnmmyE6cAIBGJS+gl+EPIFc8GoWHVUVcNk5cS2baxBNrYNh8Ov7L9geq7NhhIQ6hbnDA8AzTpnuViv1/ipGNqjFqvI5p8f3rURk7EHJaqQ/kW2BJ6jliY9z1O3YTWcAqi5zZ0wyxYe9aw6zvcLE+YqmIo8ieoLKyohJcj5BSpfUCP7raguAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "table",
          "title": "table",
          "src": "/static/859454f82ebe679269a027bd84cd709b/9e32a/table.png",
          "srcSet": ["/static/859454f82ebe679269a027bd84cd709b/222b7/table.png 163w", "/static/859454f82ebe679269a027bd84cd709b/ff46a/table.png 325w", "/static/859454f82ebe679269a027bd84cd709b/9e32a/table.png 412w"],
          "sizes": "(max-width: 412px) 100vw, 412px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{` `}</p>
    <br />
    <p>{`Pour une même table de données, plusieurs représentations peuvent être également utiles.
Soit une liste de projets: Client/Projet/Tâche/Début/Fin.
Deux manières utiles de `}<strong parentName="p">{`trier`}</strong>{` ces données sans changer l'ordre des colonnes:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`les travaux organisés par clients et projets,  `}</p>
        <br />
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "509px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.89570552147239%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB5UlEQVQoz3WSS2/iQBCE/f//QA5JblGknDhEyiFRImtZIuELEBGwiF+w2MYzfmAMxublfLaz2tOWrFGPZ7qrqnsUVVU1TcvzPI7j3W43GAyGwyHb/X7v+36v1wuCYDabLZdL4fuh6/76XaPf7ydJovxpcD6fST6dTpfL5dyAwPM8y7LSNA3DkEIyDDeDQS7Entq7Gsp8PrdtmzPXdaWUEOYNCPj/+flJidVqZdi2MxqtLWsdRWlRpFlGUUUIgbDtdptlGQnnv6iqCkXj8ZhkSpuWNdf15Pk5fHyUnU7y8ZHluVL9H+RMJhOK1rrwnKZrTZNXV+HtbXh9HT89KXjjHm5beawIwQ/KDcOYTqdskWCYpm0YCSW6XaGq4vVVatpPMiseEM9KZstMGsyygR8EYrHY6vr+eKy/0ykvy3+yIT82IDgcDgSmacJMRTpnfH0t6Gi3G1lWHEWxlEkcK0yo5dR1nXmycpUZ8odB4KIeuOdhwfW8nZSprkcPDximwwq1OYAHkUwL2/V7EAJyAt6M4zgQzB2Hs0wIeX8vbm4Ebet0fmSTzNV2KgSYLMuSF8Kc6TYNQ5U5mURvb6u7u/D9PZ5Oo9GobliLoijKBgTQtqPCBW1fJwmiqFoEwUZVNy8v5eFQVtU30zeMkS0BFJEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "gantt",
          "title": "gantt",
          "src": "/static/91508f577a0a8711d98573a5ca56a8cc/71554/gantt.png",
          "srcSet": ["/static/91508f577a0a8711d98573a5ca56a8cc/222b7/gantt.png 163w", "/static/91508f577a0a8711d98573a5ca56a8cc/ff46a/gantt.png 325w", "/static/91508f577a0a8711d98573a5ca56a8cc/71554/gantt.png 509w"],
          "sizes": "(max-width: 509px) 100vw, 509px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`ou`}</p>
    <ul>
      <li parentName="ul">{`la vision globale des tâches: de haut en bas par date de début et date de fin croissante  `}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "509px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.89570552147239%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB/klEQVQozz1SW0/yQBTc//8LfNMHY4IPvmnUBKyEJoBCyx16ofdur7TdFikgqZMl3zcPzcme3Zk5c0oEQRgMBgXHfr+fcdR1fTwegyAQRTEMQ1VVHccJHSeltPv52ev18CTLMmLbtmEYURSt12vcyDh+OFzX3W63II3j2PF9KsuV59VNc6hrdKFEdF3Hm7Isx+PxZDIZcXiehxO05vM5alzQobBcpu/v0dtbZts5Y0Wek3a73el0oKYoChTSfwA3ZPHkfD7DgmFZjq5nglCYJgPx4VAyRpbL5XQ6BTcKSJmmCamrKxRQ3u12IPUpDT2v+Ppits0UpaS0gu3L5dI0zel0kiQJY0MKKcBInufICeGB17IsVVEgHj8/hy8vca+XahqCIQ0HvF0DQ7bQOXD4vg8jiJ1S6rtunOdMFPOPDzaZsH6/nE7JmAPekBZmQ7aokySBMqYYDoeYHCy6plmel4xGUbebzOeJLKeqSv4vbbPZaJoG23ALluuqVqsVPIPIdRwax5WmJQ8P6dNT2moVkkQEDtiDMpzDNnLCt6oqEOEc/vG3oEBmZZomrVZ0dxfd3MT39wQNLAaDXf+txWKBPcMFYwyP+/1+xAE7xnbL9vvo9TW4vU1hezYjuC3LssGBIeEcE2JPyN/lQJaIEN0wCE6/vz9BkD8+Vt/fx6b5A+quePI8vpVSAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "gantt1",
          "title": "gantt1",
          "src": "/static/a4b0cb706e321895dccbca51d82ca282/71554/gantt1.png",
          "srcSet": ["/static/a4b0cb706e321895dccbca51d82ca282/222b7/gantt1.png 163w", "/static/a4b0cb706e321895dccbca51d82ca282/ff46a/gantt1.png 325w", "/static/a4b0cb706e321895dccbca51d82ca282/71554/gantt1.png 509w"],
          "sizes": "(max-width: 509px) 100vw, 509px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p>{`Changer l'ordre des colonnes est un moyen de `}<strong parentName="p">{`renverser`}</strong>{` la hiérarchie des données. Soit le livre de recette de la carte d'un restaurant:`}</p>
    <ul>
      <li parentName="ul">{`pour chaque plat, les ingrédients et la quantité nécessaires,
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "90.79754601226993%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsSAAALEgHS3X78AAACMUlEQVQ4y2XTi05iQRAEUP7/hzDZqDwVkAjyEFBAeSqgC4qCKOAe7o26xE6YzJ3p6q6qHiKLxeL4+Hg8Hn8GMZvNLi8vX15e7D8+Pq6vr0ulUrvddl6r1arVaqvVms/nZ2dnhUIhIvXg4GAwGEyn09Fo1Ov1rq6u7u7uVqvV09NTvV4HHg6H3W5Xtn2lUpEDokrk+fn57e1tu90C6DAI4vHxcblcPjw8RKPRi4sLRKypVKrf70Oqlc1mb29vI59fcX9/79u1Ku9BwKDtfLPZlMtltBFGh8Z4PH5zc/MD/h0Y1YPgC7XFYvFvEBhlMhkUfsCYh2sY9prQdnp6KhtSt0QikcvlsLNvNps/YEpY1el0OBGuTpLJJEeZZ8WcCqI4cnJyImePdthQELler41qMpnob4+88ZiIT0M5Pz/fAzNZN57xXAa3IXWW50pbbjOJi1Sk0+k9w7jiJZgcS7UKNf8JAn+aj46O0NbQJ9q7OW//i2/O5gSvEGM8DHW9DVZpaIMRtz2bvTl3gjBGDpkTIrFYjFXANLOaw2ibVj6f343Kj0gAR1ZVrQjDs4dmrdhLs5kZkgSrcxIiMHqq5xQTTpLEElbrfHh46GHqrD8JOnFELeC9R6Jho9FwAbYMgmbl+c8C9gCg7VCmt70b1SaIb7d+R3jlYRsV5saGIy92bn8n0TkJQmEqGPb6+oo/5fBoA1AU5qiy6zz7ChpaQaBKOXpS/SWZ7F/BXvr1d0WI4dP4D1Bhy++gmgR+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "resto 63TCW7AA89Y35N9OX27C",
            "title": "resto 63TCW7AA89Y35N9OX27C",
            "src": "/static/1372eb0fe05d4a821627e2875e6686bf/a6d36/resto_63TCW7AA89Y35N9OX27C.png",
            "srcSet": ["/static/1372eb0fe05d4a821627e2875e6686bf/222b7/resto_63TCW7AA89Y35N9OX27C.png 163w", "/static/1372eb0fe05d4a821627e2875e6686bf/ff46a/resto_63TCW7AA89Y35N9OX27C.png 325w", "/static/1372eb0fe05d4a821627e2875e6686bf/a6d36/resto_63TCW7AA89Y35N9OX27C.png 650w", "/static/1372eb0fe05d4a821627e2875e6686bf/e548f/resto_63TCW7AA89Y35N9OX27C.png 975w", "/static/1372eb0fe05d4a821627e2875e6686bf/3c492/resto_63TCW7AA89Y35N9OX27C.png 1300w", "/static/1372eb0fe05d4a821627e2875e6686bf/ab40b/resto_63TCW7AA89Y35N9OX27C.png 1736w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></li>
    </ul>
    <p>{`ou`}</p>
    <ul>
      <li parentName="ul">{`pour chaque ingrédient, les plats qui l'utilisent `}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "431px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "91.41104294478527%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACYUlEQVQ4y23T2U6iQRAFYN7X5YKYYEJGeSYTQ5RREUUUV1RwV2RcWFQEJZEYcT7/ZjIxM3VB6q/uU+fU6SK2v7+/tra2s7NzfX29u7tbKpXOz8+bzabi3Nxcq9X6FUW5XD48PHx+fu50Oo+Pj+l0+vj4OFYsFtfX1+/u7k5PTwuFws3Nzevra71eX1xcrNVqkp9R5HK5SqXS7Xafnp50XFlZuby8jDmG7Pf7V1dXBwcHLy8v2isuLS0RAq8jgo2NDZ842+02XXp9gX3rNBgMtMBGM+aQ03ZycgK5urrq6sXFRaPR0BoYM7LY5+cnpF8zLy8vY0B7f38PPDs7C4zZ8AiJojkwD2VDfnx8AGMDpo09tJC9ubkp1w7z0dERjZ0ogPP5/Ddm9zhsbMYg1Ojs7EyX4p/Y3t4GoJw0Hler1W9gz/bw8ECYG8CGBEaiKRVEaadoKJUhc4i3tzct39/f5Qbp9XoS5gGjNbldYKcXNaDPIfO/4eUI8U5IeGN+40C2oqBryNyNItDu7e3x3GPc3t7Oz8/PzMzwyajwEpwmchneO3+BtUeCzW5ls1k76BihnMlubG1tEUw5NlLrUZjl66kcs4EksIWFBZ4FMGa3CQkwvzyzz2FmbjuKeRWNm1EEPwTP1WmR4M9HYWz+BdnDDaPZhGZmQ0CqwkgaUeSi0AKYHZbs74bxQCmsJ9lGACAsk8kgd49aIk3u32ra/+82CfbZzOGvwjD7QIJ7XhWzvuFpFLXjdOxHFNPT04lEYnR0dGxsTOJzfHx8cnJyampKMjIyoh6Px1OplCPFiYmJZDL5G4LAjYIrWaafAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "resto1",
          "title": "resto1",
          "src": "/static/c18733444525c89a6548b13eb30f5c41/9cb4e/resto1.png",
          "srcSet": ["/static/c18733444525c89a6548b13eb30f5c41/222b7/resto1.png 163w", "/static/c18733444525c89a6548b13eb30f5c41/ff46a/resto1.png 325w", "/static/c18733444525c89a6548b13eb30f5c41/9cb4e/resto1.png 431w"],
          "sizes": "(max-width: 431px) 100vw, 431px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`Une table structurée en Excel`}</h3>
    <p>{`Les principes utilisés pour cette réalisation:`}</p>
    <ul>
      <li parentName="ul">{`formatage par factorisation visuelle de gauche à droite`}</li>
      <li parentName="ul">{`tri à l'intérieur des colonnes`}</li>
      <li parentName="ul">{`déplacement, changement de l'ordre des colonnes
Le formatage est obtenu grâce à des formats conditionnels qui masquent les champs redondants et tracent les bordures. Les données sont toujours là. La table est éditable et la modification de champs provoque l'adaptation de la présentation.
Chaque colonne peut être triée par double clic. Un premier double clic trie de haut en bas et un deuxième dans l'ordre inverse en alternance. Deux variantes: `}</li>
      <li parentName="ul"><strong parentName="li">{`total`}</strong>{`, toute la table est triée: double clic sur le titre de la colonne`}</li>
      <li parentName="ul"><strong parentName="li">{`partiel`}</strong>{`, l'ordre des champs à la gauche de la colonne est préservé: double clic sur un élément de la colonne.
C'est l'ordre des colonnes (de gauche à droite) qui détermine la structuration des données qui est mise en avant par le formatage. C'est une capacité très méconnue d'Excel qui permet de changer cet ordre: le `}<strong parentName="li">{`déplacement`}</strong>{` d'une zone de données. Il faut après la sélection de la colonne, `}<strong parentName="li">{`maintenir shift`}</strong>{`,   prendre la sélection avec `}<strong parentName="li">{`le bouton gauche maintenu appuyé`}</strong>{`,  `}<strong parentName="li">{`glisser déposer`}</strong>{` et `}<strong parentName="li">{`relâcher shift`}</strong>{`.`}</li>
    </ul>
    <br />
    <center> 
      <p><img parentName="p" {...{
          "src": "/gifs/DragColumn.gif",
          "alt": null
        }}></img>{` `}</p>
    </center>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      